import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.scss';
import ProtectedRoutes from 'routes/ProtectedRoutes';
import routes, { allRoutes, publicRoutes } from 'routes/routes';
import PublicRoutes from 'routes/PublicRoutes';
import { auth, onAuthStateChanged } from './firebase';
import { loginSuccess } from './features/Auth/authenticationSlice';
import constants from 'common/utils/constants';

const NotFoundPage = lazy(() =>
  import('common/sharedComponents/NotFoundPage/NotFoundPage'),
);

function App() {
  //Getting isAuthenticated store value from Authentication slice.
  const [isAuthenticated, setIsAuthenticated] = useState(
    window.localStorage.getItem('token_fairatmos'),
  );
  const redirect = useSelector((state) => state.authentication.redirectPath);
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          window.localStorage.setItem('token_fairatmos', user.accessToken);
          const customAttrs = JSON.parse(
            user.reloadUserInfo.customAttributes || '{}',
          );
          const userData = {
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            userId: customAttrs.fairatmos_user_id,
            roles: customAttrs.roles,
          };
          window.localStorage.setItem(
            'user_fairatmos',
            JSON.stringify(userData),
          );
          if (redirect !== undefined) {
            window.localStorage.setItem('redirect', JSON.stringify(redirect));
          }
          setIsAuthenticated(true);
          dispatch(
            loginSuccess({
              token: user.accessToken,
              user: userData,
            }),
          );
        } else {
          window.localStorage.removeItem('token_fairatmos');
          window.localStorage.removeItem('user_fairatmos');
          window.localStorage.removeItem('redirect');
          setIsAuthenticated(false);
        }
      });
    };
    f();
  });

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {allRoutes.map(({ path, component, redirect }, index) => {
            if (isAuthenticated) {
              return (
                <PrivateRoute
                  key={index}
                  path={`/${path}`}
                  isAuthenticated={isAuthenticated}
                >
                  <ProtectedRoutes component={component} />
                </PrivateRoute>
              );
            }
            return (
              <PublicRoute
                key={index}
                path={`/${path}`}
                isAuthenticated={isAuthenticated}
              >
                <PublicRoutes component={component} />
              </PublicRoute>
            );
          })}
          {publicRoutes.map(
            ({ path, component, redirect, hideHeader }, index) => (
              <PublicRoute
                key={index}
                path={`/${path}`}
                isAuthenticated={isAuthenticated}
              >
                {redirect ? (
                  <Redirect
                    to={{
                      pathname: '/sign-in',
                      state: { from: path },
                    }}
                  />
                ) : (
                  <PublicRoutes hideHeader={hideHeader} component={component} />
                )}
              </PublicRoute>
            ),
          )}
          {routes.map(({ path, component, hideHeader }, index) => (
            <PrivateRoute
              key={index}
              path={`/${path}`}
              isAuthenticated={isAuthenticated}
            >
              <ProtectedRoutes hideHeader={hideHeader} component={component} />
            </PrivateRoute>
          ))}
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

// Public route restrict to access authenticated pages before login.
function PrivateRoute({ children, isAuthenticated, ...rest }) {
  const redirect = useSelector((state) => state.authentication.redirectPath);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname:
                redirect === null &&
                !rest.location.pathname.includes('projects')
                  ? `/sign-in?redirect_to=${rest.location.pathname}`
                  : '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// Private route restrict to access public pages after login.
function PublicRoute({ children, isAuthenticated, ...rest }) {
  let redirectPath =
    rest.location.search.includes('redirect_to') &&
    rest.location.search.split('=');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname:
                redirectPath.length > 1 && redirectPath[1] !== ''
                  ? redirectPath[1]
                  : constants.HOME_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
