import { lazy } from 'react';

const routes = [
  {
    path: 'users',
    component: lazy(() => import('features/Users')),
    exact: true,
  },
  {
    path: 'dashboard',
    component: lazy(() => import('features/Dashboard')),
    exact: true,
  },
  {
    path: 'eligibility/:param/:page?',
    component: lazy(() => import('features/Eligibility')),
    exact: false,
  },
  {
    path: 'projects/:param?/:id?',
    component: lazy(() => import('features/Project')),
    exact: false,
  },
  {
    path: 'projects',
    component: lazy(() => import('features/Project')),
    exact: false,
  },
  {
    path: 'profile/:param?',
    component: lazy(() => import('features/Profile')),
    exact: false,
  },
  {
    path: 'update-password',
    component: lazy(() => import('features/Auth/ChangePassword')),
    exact: true,
  },
  {
    path: 'expert/:id?',
    component: lazy(() => import('features/Expert/ExpertSchedule')),
    exact: false,
  },
  {
    path: 'expert/:id?',
    component: lazy(() => import('features/Expert/ExpertSchedule')),
    exact: false,
  },
  {
    path: 'payment/:status',
    component: lazy(() => import('features/Expert/ExpertPayment')),
    exact: false,
  },
  {
    path: 'survey',
    component: lazy(() => import('features/Expert/ExpertSurvey')),
    exact: false,
  },
  {
    path: 'suggestion',
    component: lazy(() => import('features/Expert/ExpertSuggestion')),
    exact: false,
  },
  {
    path: 'experts',
    component: lazy(() => import('features/Expert/ExpertList')),
    exact: true,
  },
  {
    path: 'project/:id/detail',
    component: lazy(() => import('features/Project/ProjectDetail')),
    exact: true,
  },
  {
    path: 'create-project/:param?/:id?',
    component: lazy(() =>
      import('features/CreateProject/CreateProjectWrapper'),
    ),
    exact: false,
  },
];

export const publicRoutes = [
  {
    path: '/',
    component: lazy(() => import('features/LandingPage/LandingPage')),
    exact: true,
    redirect: true,
  },
  {
    path: 'sign-in:path?',
    component: lazy(() => import('features/Auth/SignIn')),
    hideHeader: true,
    exact: true,
  },
  {
    path: 'sign-up/:role?',
    component: lazy(() => import('features/Auth/SignUp')),
    hideHeader: true,
    exact: true,
  },
  {
    path: 'sign-up-success',
    component: lazy(() => import('features/Auth/SignUpSuccess')),
    exact: true,
  },
  {
    path: 'verify',
    component: lazy(() => import('features/Auth/Verify')),
    exact: true,
  },
  {
    path: 'reset-password',
    component: lazy(() => import('features/Auth/ResetPassword')),
    exact: true,
  },
  {
    path: 'change-password',
    component: lazy(() => import('features/Auth/ChangePassword')),
    exact: true,
  },
  {
    path: 'check-email',
    component: lazy(() => import('features/Auth/SignUpSuccess')),
    exact: true,
  },
];

export const allRoutes = [
  {
    path: 'change-password',
    component: lazy(() => import('features/Auth/ChangePassword')),
    exact: true,
  },
  {
    path: 'sign-out',
    component: lazy(() => import('features/Auth/SignOut')),
    exact: true,
  },
];

export default routes;
