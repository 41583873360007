const SIGNIN_ID = {
  title: 'Masuk',
  subtitle: 'Selamat datang kembali!',
  password: 'Kata Sandi',
  old_password: 'Kata Sandi Lama',
  new_password: 'Kata Sandi Baru',
  required_password_message: 'Silakan masukkan kata sandi!',
  forgot_password: 'Lupa kata sandi?',
  signin_button_label: 'Masuk',
  dont_have_account: 'Tidak punya akun? ',
  signup: 'Daftar',
  save: 'Simpan Perubahan',
  label_name: 'Nama Lengkap',
  label_email: 'Email',
  label_organization: 'Nama Organisasi',
  label_phone: 'Nomor Handphone',
  placeholder_name: 'Masukkan Nama Lengkap',
  placeholder_email: 'Masukkan Email Aktif',
  placeholder_phone: 'Masukkan Nomor Handphone',
  placeholder_organization: 'Masukkan Nama Lengkap Organisasi',
  placeholder_password: 'Masukkan Kata Sandi',
  placeholder_reenter_password: 'Konfirmasikan Kata Sandi',
  placeholder_old_password: 'Masukkan Kata Sandi Lama',
  placeholder_new_password: 'Masukkan Kata Sandi Baru',
  placeholder_confirm_password: 'Konfirmasikan Kata Sandi Baru',
  welcome_to_fairatmos: 'Selamat datang di Fairatmos!',
  input_name: 'Silakan ketik nama Anda!',
  input_organization: 'Silakan ketik nama organisasi Anda!',
  input_phone: 'Silakan ketik nomor handphone Anda!',
  min_3_character: 'Anda butuh min. 3 karakter',
  min_8_character: 'Anda butuh min. 8 karakter',
  min_6_character: 'Isi nomor handphone min. 6 digit',
  max_13_character: 'Isi nomor handphone max. 13 digit',
  max_50_character: 'Max. 50 karakter',
  max_100_character: 'Max. 100 karakter',
  max_250_character: 'Max. 250 karakter',
  invalid_email: 'Input email tidak valid!',
  missing_email: 'Silakan ketik e-mail Anda!',
  missing_password: 'Silakan ketik kata sandi Anda!',
  invalid_phone: 'Isi nomor handphone min. 6 digit dan max. 13 digit',
  invalid_password: 'Kata sandi wajib berisi huruf besar dan angka',
  reenter_password: 'Konfirmasi Kata Sandi',
  missing_password_reenter: 'Silakan ketik ulang sandi!',
  different_password: 'Masukkan kata sandi yang sama',
  agree_to: 'Saya menyetujui ',
  and_our: ' dan ',
  already_have_account: 'Sudah punya akun? ',
  one_billion:
    'Dari lebih dari 1 Miliar Ton Gas Emisi Rumah Kaca yang perlu diserap di dunia, kita baru memiliki rencana untuk mengurangi 15 juta Ton. Ayo bergabung untuk membuat proyek pengurangan emisi!',
  forgot_password_instruction:
    'Ketik email yang Anda gunakan dan kami akan mengirim petunjuk untuk me-reset kata sandi.',
  forgot_password_button: 'Kirim email petunjuk reset kata sandi',
  sent_reset_password_success: 'Berhasil reset kata sandi!',
  check_your_email: 'Silakan cek email Anda untuk petunjuk reset kata sandi',
  sent_reset_password_failed: 'Gagal reset kata sandi!',
  failed_change_password: 'Gagal ganti kata sandi',
  succeed_change_password: 'Berhasil ganti kata sandi',

  succeed_create_account: 'Akun berhasil dibuat',
  failed_create_account: 'Akun gagal dibuat',
  desc_failed_create_account: 'Pengguna dengan email terkait sudah terdaftar',

  failed_logout: 'Gagal keluar',
  failed_login: 'Gagal masuk',
  failed_login_msg: 'Email atau kata sandi Anda salah',

  error_email_exists: 'Email sudah pernah didaftarkan',
  error_email_not_found: 'Email tidak ditemukan.',
  error_invalid_password:
    'Kata sandi salah. Silakan coba lagi atau klik lupa kata sandi untuk di-reset.',
  error_weak_password: 'Kata sandi harus lebih dari 6 huruf.',
  error_expired_oob_code: 'Kode OOB sudah kadaluarsa',
  change_password_success: 'Berhasil mengubah kata sandi',
  change_password_failed: 'Gagal mengubah kata sandi',
  send_email_success: 'Email berhasil dikirimkan',
  send_email_failed: 'Email gagal dikirimkan',
  verification_success: 'Verifikasi email berhasil',
  verification_failed: 'Verifikasi email gagal',
};

export { SIGNIN_ID };
